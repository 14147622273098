export const routes = {
  default: "/",
  apiKeyRoutes: "/api-keys",
  usageRoutes: "/usage",
  termsOfServiceRoutes: "/terms-of-service",
  billingRoutes: "/billing",
  accountRoutes: "/account",
};

export const billingRoutes = {
  overviewRoutes: "/overview",
  paymentMethodsRoutes: "/payment-methods",
  historyRoutes: "/history",
  costsRoutes: "/costs",
  preferencesRoutes: "/preferences",
};

export const accountRoutes = {
  profile: "/profile",
  rateLimits: "/rate-limits",
};

export const routesDataMap: {
  [key: string]: string;
} = {
  [routes.default]: "API Keys",
  [routes.apiKeyRoutes]: "API Keys",
  [routes.usageRoutes]: "Usage",
  [routes.termsOfServiceRoutes]: "Terms of Service",
  [routes.billingRoutes]: "Billing",
  [routes.billingRoutes + billingRoutes.overviewRoutes]: "Billing",
  [routes.billingRoutes + billingRoutes.paymentMethodsRoutes]: "Billing",
  [routes.billingRoutes + billingRoutes.historyRoutes]: "Billing",
  [routes.billingRoutes + billingRoutes.costsRoutes]: "Billing",
  [routes.billingRoutes + billingRoutes.preferencesRoutes]: "Billing",
  [routes.accountRoutes + accountRoutes.profile]: "Profile",
  [routes.accountRoutes + accountRoutes.rateLimits]: "Rate Limits",
};
