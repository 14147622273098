import axios from "../utils/axios";

const accountPath = "account";
const rateLimitsPath = "/rate-limits";
const profilePath = "/profile";

export const getAccountRateLimits = () => axios.get(`${accountPath}${rateLimitsPath}`);

export const getProfile = () => axios.get(`${accountPath}${profilePath}`);

export const updateProfile = (name: string) =>
  axios.put(`${accountPath}${profilePath}`, {
    name,
  });
